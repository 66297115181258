import React from 'react'
import Layout from '../components/layouts/default'

const NotFoundPage = () => (



	<Layout>

		<div className="container-fluid ">
			<h1 className="text-white text-center mt-5 mb-5" style={{letterSpacing: '0.06em', fontWeight: 200 }}>NOT FOUND</h1>
			<div className="container">

				<div className="row">
					<div className="col-md-8 offset-2 mb-4">
						<div className="card card-feature">
							<div className="help-body" style={{padding: 40}}>
								<p className="pt-2" style={{fontWeight: 100, fontSize: 18}}>
									Sorry, but that page wasn't found. You may have entered an incorrect url or followed an outdated link.
								</p>

							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>


	</Layout>




)

export default NotFoundPage
